<template>
    <div>
        <el-row style="padding:20px 0 10px">
            <el-col :span="6">交易笔数：{{allCount.totalOrderCount || 0}}笔</el-col>
            <el-col :span="6">交易金额：{{allCount.totalTransAmount || 0}}元</el-col>
            <el-col :span="6">结算金额：{{allCount.totalSettleAmount || 0}}元</el-col>
        </el-row>
        <el-table border v-loading="loading" :data="dataList" >
            <el-table-column label="订单号" prop="orderNo" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="上游订单号" prop="outOrderNo" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="渠道" prop="channelNo" min-width="120" :show-overflow-tooltip="true" >
                <template slot-scope="scope">
                    {{ scope.row.channelNo | channelFM }}
                </template>
            </el-table-column>
            <el-table-column label="支付类型" prop="payTypeCode" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.payTypeCode | payType}}
                </template>
            </el-table-column>
            <el-table-column label="商户编号" prop="merchantNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="上游商户编号" prop="outMerchantNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="商户名称" prop="lsMerchantName" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="直属机构编号" prop="orgNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="一级代理编号" prop="oneAgentNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="一级代理名称" prop="oneAgentName" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="代理商编号" prop="agentNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="代理商名称" prop="agentName" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="终端号" prop="sn" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="卡号" prop="accountNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="卡类型" prop="cardType" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.cardType | formateCardType}}
                </template>
            </el-table-column>
            <el-table-column label="交易金额(元)" prop="transAmount" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="手续费(元)" prop="settleFee" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="费率" prop="merchantRate" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="结算金额" prop="settleAmount" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="交易状态" prop="transStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.transStatus | transStatusFM}}
                </template>
            </el-table-column>
            <el-table-column label="交易类型" prop="settleType" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <template v-if="scope.row.settleType">T</template>{{scope.row.settleType}}
                </template>
            </el-table-column>
            <el-table-column label="结算状态" prop="settleStatus" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.settleStatus | settleStatusFM}}
                </template>
            </el-table-column>
            <el-table-column label="交易时间" prop="transTime" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="180" fixed="right">
                <template slot-scope="scope">
                    <el-button size="mini" type="text"
                               @click="handleBack(scope.row.orderNo)"
                               v-permission="'HIDE_DATA_UPDATE'">回退</el-button>
                </template>
            </el-table-column>
        </el-table>

        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
    </div>
</template>

<script>
    import {TradeApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false,
                allCount:''
            }
        },
        mounted() {
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await TradeApi.hideDataQueryPage(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data.orderPageList || [];
                this.allCount = result.data.orderCountInfo || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            async handleBack(no) {
                let result = await TradeApi.fallback(no)
                if(result.success){
                    this.Message.success("回退成功！")
                    this.getList();
                }
            },
        }
    };
</script>

<style scoped>

</style>
